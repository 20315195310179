// extracted by mini-css-extract-plugin
export var CardWrapper = "RobertNava-module--CardWrapper--EPf+J";
export var CarrouselWrapper = "RobertNava-module--CarrouselWrapper--ASsxu";
export var DescriptionWrapper = "RobertNava-module--DescriptionWrapper--vpZR7";
export var ExpoWrapper = "RobertNava-module--ExpoWrapper--HZPEj";
export var NameWrapper = "RobertNava-module--NameWrapper--mI2v+";
export var PdpWrapper = "RobertNava-module--PdpWrapper--DPwiP";
export var PhotosWrapper = "RobertNava-module--PhotosWrapper--Hfrjd";
export var ProfilWrapper = "RobertNava-module--ProfilWrapper---d4wa";
export var VideoWrapper = "RobertNava-module--VideoWrapper--ZpphK";
export var VideosWrapper = "RobertNava-module--VideosWrapper--8yqpu";
export var WorkWrapper = "RobertNava-module--WorkWrapper--fcreg";
export var WorksWrapper = "RobertNava-module--WorksWrapper--5q0Fb";
export var Wrapper = "RobertNava-module--Wrapper--e+cvO";