import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CarrouselWrapper,
CarrouselWrapper2,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
} from "./RobertNava.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import RobertNavaPhoto1 from "../../../../res/Photos site/Robert Nava/Robert Nava - Atelier - Avant Arte.webp"
import RobertNavaPhoto2 from "../../../../res/Photos site/Robert Nava/Robert Nava, Smoke Tint, 2017 HD.jpg"
import RobertNavaPhoto3 from "../../../../res/Photos site/Robert Nava/Robert-Nava-Matteo-Mobilio-Pace-Gallery-683x1024.jpg"
import RobertNavaPhoto4 from "../../../../res/Photos site/Robert Nava/Robert Nava by Matteo Mobilio.webp"

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "RobertNava",
  name: "RobertNava",
  description:"Robert Nava est un artiste américain né en 1985 à Chicago. Diplômé en 2008 de la Indiana Northwest University et en 2011 en peinture de la Yale University School, il est aujourd’hui représenté par Sorry We’re Closed, Bruxelles ainsi que Pace Galleries. Robert Nava est connu pour son approche mystique de la figuration avec ses créatures aux couleurs électriques composées dans des toiles de grandes dimensions. Un art innocent, inspiré de ses souvenirs, de ses rêves ou de ses cauchemars. Il cherche à créer une mythologie contemporaine avec des créatures métaphoriques telles que les anges, les requins ou les clowns, sont autant d’images qui composent le lexique visuel personnel de Nava. Tirant ses inspirations des cultures Maya et Égyptienne notamment, Robert Nava a eu l’occasion de présenter plusieurs expositions personnelles, dont à la ThreeFourThreeFour Gallery et à la Night Gallery, New York, Sorry We’re Closed, Bruxelles, V1 Gallery, Copenhague et récemment à la Pace Gallery, South Hamptons et son travail fait partie de collections permanentes telles que celles du Art Institute de Chicago et celles du Musée d’Art Moderne de la Ville de Paris. ",
  pdp: RobertNavaPhoto3,
  alt_pdp: "Photo de profil de RobertNava.",
  photos: [
    { src: RobertNavaPhoto1, name: "RobertNava" },
    { src: RobertNavaPhoto2, name: "RobertNava" },
    { src: RobertNavaPhoto4, name: "RobertNava" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
};

const RobertNava = () => {
    return (
        <div className={Wrapper}>
        <div className={CardWrapper}>
          <div className={ProfilWrapper} id="f">
            <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
          </div>
          <div>
            <h1 className={NameWrapper}>Robert Nava</h1>
            <h3 className={NameWrapper} style={{paddingTop: '0'}}>Né en 1958</h3>
            <p className={DescriptionWrapper}>{infos.description}</p>
          </div>
        </div>
        <div className={PhotosWrapper}>
          <Title title={"Past or Present"} />
          <div className={CarrouselWrapper2}>
          </div>
        </div>
      </div>
      );
};

export default RobertNava;